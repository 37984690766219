import { useContext, useState } from "react";
import Alerts from "../../../../components/alerts/Alerts";
import BtnAccept from "../../../../components/forms/BtnAccept";
import CheckBox from "../../../../components/forms/CheckBox";
import Input from "../../../../components/forms/Input";
import InputCKEditor from "../../../../components/forms/InputCKEditor";
import TextTarea from "../../../../components/forms/TextTarea";
import { authContext } from "../../../../context/AuthContext";
import ApiRest from "../../../../hooks/useRest";
import { Validate } from "../../../../utils/ValidateForms";
import { AttachPayments } from "../../../../utils/AttachPayments";

const ModalPrice = ({
  modal,
  setModal,
  form,
  setForm,
  validate,
  setLoading,
  loading,
  steps,
  setSteps,
  setSelect,
  select,
  id,
  data,
  payment_config
}) => {
  const context = useContext(authContext);
  const { FORM, VALIDATOR, URLs } = context;

  const { URL_UPDATE_PRICE, URL_CREATE_PRICE } = URLs;
  const { FORM_PRICES } = FORM;
  const { VALIDATION_PRICES } = VALIDATOR;

  const [formPrices, setFormPrices] = useState(
    JSON.parse(JSON.stringify(select ? select : FORM_PRICES))
  );

  const [validatePrices, setValidatePrices] = useState(
    JSON.parse(JSON.stringify(VALIDATION_PRICES))
  );

  const onClick = async (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
      case "add_price":
        {
          //Delete property
          const validation = Validate(formPrices);
          setValidatePrices(validation);

          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }


          if (id) {
            let formData = new FormData();

            formData.append("projects_id", id);
            formData.append("id", formPrices.id[0]);
            formData.append("name", formPrices.name[0]);
            formData.append("description", formPrices.description[0]);
            formData.append("price", formPrices.price[0]);
            formData.append("time_lapse", formPrices.time_lapse[0]);
            formData.append("premium", modal == "price_premium" ? 1 : 0);

            formPrices.payment_types_id[0].map((payment, index) => {
              formData.append("[payment_types_id][" + index + "]", payment);
            });

            const response = await ApiRest({
              url: URL_CREATE_PRICE,
              formData: formData,
              setLoading: setLoading,
              method: "post",
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              setSteps(steps + 1);

              //cerrar modal
              Alerts({
                msg: "Alert",
                title: "Nuevo servicio creado",
                code: "201",
                type: "success",
              });

              return setModal(false);
            }

            Object.keys(response.data).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            });
          }

          formPrices.premium[0] = modal == "price_premium" ? 1 : 0;
          let setCampo = form;
          setCampo[modal][0] = formPrices;


          setForm({
            ...form,
            [modal]: setCampo[modal],
          });

          setSelect(null);
          return setModal(false);
        }
        break;
      case "save_prices":
        {
          if (select && JSON.parse(id)) {
            let formData = new FormData();

            formData.append("id", formPrices.id[0]);
            formData.append("name", formPrices.name[0]);
            formData.append("description", formPrices.description[0]);
            formData.append("price", formPrices.price[0]);
            formData.append("time_lapse", formPrices.time_lapse[0]);

            formPrices.payment_types_id[0].map((payment, index) => {
              formData.append("payment_types_id[" + index + "]", payment);
            });

            const response = await ApiRest({
              url: URL_UPDATE_PRICE,
              formData: formData,
              setLoading: setLoading,
              method: "post",
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              setSteps(steps + 1);

              //cerrar modal
              Alerts({
                msg: "Alert",
                title: "Nuevo servicio creado",
                code: "201",
                type: "success",
              });

              return setModal(false);
            }

            Object.keys(response.data).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            });
          }

          //Delete property
          const validation = Validate(formPrices);
          setValidatePrices(validation);

          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          let type = select.premium[0] == 1 ? "price_premium" : "price_basico";

          let setCampo = form;
          setCampo[type][0] = formPrices;

          setForm({
            ...form,
            [type]: setCampo[type],
          });

          setSelect(null);
          return setModal(false);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "payment_config":
      case "payment_name":
        {
          let setCampo = formPrices;
          setCampo[e.target.name][0] = e.target.value;

          setFormPrices({
            ...formPrices,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      case "payment_types_id":
        {
          let setCampo = formPrices;
          let array = setCampo[e.target.name][0];

          if (e.target.checked) {
            array.push(e.target.value);
          } else {
            array.splice(array.indexOf(e.target.value), 1);
          }

          setCampo[e.target.name][0] = array;

          setFormPrices({
            ...formPrices,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      case "description":
      case "price":
      case "time_lapse":
      case "name":
        {
          let setCampo = formPrices;
          setCampo[e.target.name][0] = e.target.value;

          setFormPrices({
            ...formPrices,
            [e.target.name]: setCampo[e.target.name],
            //["premium"]: [modal == "price_premium" ? "1" : "0", ""],
          });
        }
        break;
    }
  };

  console.log({formPrices});
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <div className="modal-header p-4">
            <h1 className="modal-title fs-3" id="nueva-ordenModalLabel">
              Añadir variable "{modal}"
            </h1>
            <label className="btn btn-close">
              <button
                type="button"
                className="d-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                name="close"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>
          <div className="modal-body p-5">
            <form>
              <div className="mb-3">
                <Input
                  onChange={onChange}
                  label={"Nombre del servicio"}
                  name={"name"}
                  type={"text"}
                  placeholder={"Nombre del servicio"}
                  value={formPrices.name[0]}
                  validate={validatePrices.name}
                />
              </div>
              <div className="mb-3 d-flex align-items-center">
                <div className="me-1">
                  <Input
                    onChange={onChange}
                    label={"Precio"}
                    name={"price"}
                    type={"number"}
                    placeholder={"Precio"}
                    value={formPrices.price[0]}
                    validate={validatePrices.price}
                  />
                </div>
                <div className="mt-2 d-flex align-items-center mt-3 fs-4 fw-bold">
                  USD
                </div>
              </div>
              <div className="mb-3 ">
                <label className="form-label">Descripción del servicio</label>
                <InputCKEditor
                  data={formPrices.description[0]}
                  name="description"
                  onChange={onChange}
                />
              </div>
              <div className="mb-3">
                <Input
                  onChange={onChange}
                  label={"Tiempo"}
                  name={"time_lapse"}
                  type={"text"}
                  placeholder={"Tiempo"}
                  value={formPrices.time_lapse[0]}
                  validate={validatePrices.name}
                />
              </div>
              <div className="mb-3 w-100">
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <h4 class="m-0">Formas de pago</h4>
                </div>
                <form action="" className="fs-5">
                  {data.map((payment) => (
                    <div className="d-flex justify-content-between mb-2">
                      <div>
                        <CheckBox
                          onChange={onChange}
                          label={payment.name}
                          name={"payment_types_id"}
                          type={"checkbox"}
                          value={
                            formPrices.payment_types_id[0].length > 0
                              ? formPrices.payment_types_id[0].indexOf(String(payment.id)) >= 0
                                ? true
                                : false
                              : AttachPayments(
                                formPrices.payment_types_id[0],
                                select ? select.payment_types : []
                              ).indexOf(String(payment.id)) >= 0
                                ? true
                                : false
                          }
                          defaultValue={payment.id}
                          validate={{ msg: "", validate: false }}
                        />
                      </div>
                      <div>
                        <label className="btn btn-secondary me-1">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_548_616)">
                              <path
                                d="M22.7984 4.80035H16.7988V2.79648C16.7707 2.02832 16.4391 1.30266 15.8768 0.778575C15.3145 0.254491 14.5673 -0.0252491 13.799 0.00067745H10.1993C9.43105 -0.0252491 8.68388 0.254491 8.12155 0.778575C7.55923 1.30266 7.22765 2.02832 7.1995 2.79648V4.80035H1.19992C0.881679 4.80035 0.576476 4.92677 0.351448 5.15179C0.126419 5.37682 0 5.68203 0 6.00026C0 6.3185 0.126419 6.62371 0.351448 6.84873C0.576476 7.07376 0.881679 7.20018 1.19992 7.20018H2.39983V20.3993C2.39983 21.354 2.77909 22.2696 3.45418 22.9447C4.12926 23.6198 5.04487 23.999 5.99959 23.999H17.9988C18.9535 23.999 19.8691 23.6198 20.5442 22.9447C21.2193 22.2696 21.5985 21.354 21.5985 20.3993V7.20018H22.7984C23.1167 7.20018 23.4219 7.07376 23.6469 6.84873C23.8719 6.62371 23.9983 6.3185 23.9983 6.00026C23.9983 5.68203 23.8719 5.37682 23.6469 5.15179C23.4219 4.92677 23.1167 4.80035 22.7984 4.80035ZM9.59934 2.79648C9.59934 2.6045 9.85132 2.40051 10.1993 2.40051H13.799C14.147 2.40051 14.399 2.6045 14.399 2.79648V4.80035H9.59934V2.79648ZM19.1987 20.3993C19.1987 20.7175 19.0723 21.0227 18.8472 21.2477C18.6222 21.4728 18.317 21.5992 17.9988 21.5992H5.99959C5.68135 21.5992 5.37614 21.4728 5.15112 21.2477C4.92609 21.0227 4.79967 20.7175 4.79967 20.3993V7.20018H19.1987V20.3993Z"
                                fill="#00214B"
                              />
                              <path
                                d="M9 17C9.26522 17 9.51957 16.8946 9.70711 16.7071C9.89464 16.5196 10 16.2652 10 16V12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12V16C8 16.2652 8.10536 16.5196 8.29289 16.7071C8.48043 16.8946 8.73478 17 9 17ZM15 17C15.2652 17 15.5196 16.8946 15.7071 16.7071C15.8946 16.5196 16 16.2652 16 16V12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11C14.7348 11 14.4804 11.1054 14.2929 11.2929C14.1054 11.4804 14 11.7348 14 12V16C14 16.2652 14.1054 16.5196 14.2929 16.7071C14.4804 16.8946 14.7348 17 15 17Z"
                                fill="#00214B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_548_616">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <button
                            class="d-none"
                            name="delete-payment-method"
                            onClick={(event) => onClick(event, payment)}
                          ></button>
                        </label>
                      </div>
                    </div>
                  ))}

                  <div class="col-md-12 d-flex">
                    <Input
                      onChange={onChange}
                      label={"A CONVENIR"}
                      name={"payment_name"}
                      type={"number"}
                      placeholder={
                        formPrices.payment_config[0] == "cuota"
                          ? "Definir cuotas"
                          : formPrices.payment_config[0] == "descuento"
                            ? "Definir descuento"
                            : ""
                      }
                      value={formPrices.payment_name[0]}
                      validate={{ msg: "", validate: false }}
                    />

                    <div className="ms-2 d-flex">
                      {payment_config.clave.map((clave) => (
                        <div class="form-check ms-3">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_config"
                            id="payment_config_"
                            value={clave}
                            onChange={(event) => onChange(event)}
                            checked={clave == formPrices.payment_config[0] ? true : false}
                          />
                          <label class="form-check-label" for="flexRadioDefault1">
                            {clave}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </form>
              </div>

              <div className="w-100 d-flex justify-content-end">
                {select ? (
                  <BtnAccept
                    onClick={onClick}
                    name={"save_prices"}
                    value={"GUARDAR SERVICIO OPCIONAL"}
                    loading={loading}
                    loadingMsg={"Creando..."}
                  />
                ) : (
                  <BtnAccept
                    onClick={onClick}
                    name={"add_price"}
                    value={"PUBLICAR SERVICIO OPCIONAL"}
                    loading={loading}
                    loadingMsg={"Creando..."}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPrice;
