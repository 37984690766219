const CheckBox = ({ onChange, label, name, type, value, defaultValue, uniqueKey, disabled }) => {
  return (
    <div className="form-check mb-3 m-0 p-0">
      <input
        type={type}
        name={name}
        onChange={(event) => onChange(event)}
        value={defaultValue}
        className="form-check-input m-0 my-1 me-1"
        id={uniqueKey}
        checked={value}
        disabled={disabled}
      />
      <label className="form-check-label" for="customCheck1">
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
