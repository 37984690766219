export const VALIDATOR = {
  VALIDATION_LOGIN: {
    email: { validate: false, msg: "" },
    password: { validate: false, msg: "" },
  },

  VALIDATION_PROJECT: {
    title: { validate: false, msg: "" },
    description: { validate: false, msg: "" },
    price: { validate: false, msg: "" },
    link: { validate: false, msg: "" },
    status_terms_and_conditions: { validate: false, msg: "" },
    time_lapse: { validate: false, msg: "" },
    payment_types_id: { validate: false, msg: "" },
    users_id: { validate: false, msg: "" },
    terms_and_conditions_id: { validate: false, msg: "" },
    clients_id: { validate: false, msg: "" },
    types_id: { validate: false, msg: "" },
    status_id: { validate: false, msg: "" },
    methodology_id: { validate: false, msg: "" },

  },

  VALIDATION_CLIENTS: {
    name: { validate: false, msg: "" },
    last_name: { validate: false, msg: "" },
    email: { validate: false, msg: "" },
    business_name: { validate: false, msg: "" },
    description: { validate: false, msg: "" },
    regions_id: { validate: false, msg: "" },
    status_id:{ validate: false, msg: "" },
  },

  VALIDATION_PRICES: {
    name: { validate: false, msg: "" },
    description: { validate: false, msg: "" },
    price: { validate: false, msg: "" },
    projects_id: { validate: false, msg: "" },
    status_id:{ validate: false, msg: "" },
    premium:{ validate: false, msg: "" },
  },

  VALIDATION_SERVICES: {
    name: { validate: false, msg: "" },
    subname: { validate: false, msg: "" },
    description: { validate: false, msg: "" },
    unid: { validate: false, msg: "" },
    price: { validate: false, msg: "" },
    duration_months: { validate: false, msg: "" },
    unique: { validate: false, msg: "" },
    status_id: { validate: false, msg: "" },
  },

  VALIDATION_PROMOTIONS: {
    descont: { validate: false, msg: "" },
    time_duration: { validate: false, msg: "" },
    status_id:{ validate: false, msg: "" },
  },

  VALIDATION_FEATURES: {
    name: { validate: false, msg: "" },
    description: { validate: false, msg: "" },
    features_types_id: { validate: false, msg: "" },
    status_id:{ validate: false, msg: "" },
  },

  VALIDATION_FAQS: {
    question: { validate: false, msg: "" },
    response: { validate: false, msg: "" },
    status_id:{ validate: false, msg: "" },
  },

  VALIDATION_METHODOLOGY: {
    name: { validate: false, msg: "" },
    description: { validate: false, msg: "" },
  }
}