import { configureStore } from "@reduxjs/toolkit";
// reducer
import me from "../slices/users/Index"
import projects from "../slices/projects/Index"
import payment_types from "../slices/payments_types/Index"
import methodologies from "../slices/methodologies/Index"
import features from "../slices/features/Index"
import clients from "../slices/clients/Index"
import faqs from "../slices/faqs/Index"
import regions from "../slices/regions/Index"
import model_service from "../slices/model_services/Index"


export default configureStore({
  reducer: {
    me,
    projects,
    payment_types,
    methodologies,
    features,
    clients,
    faqs,
    regions,
    model_service
  }
})