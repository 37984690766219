export const URLs = {
  URL_LOGIN: "/Login",
  URL_LOGOUT: "/Auth/Logout",
  URL_ME: "/Auth/Me",

  /* Features */
  URL_INDEX_FEATURES_TYPES: "/Auth/FeaturesTypes",
  URL_INDEX_FEATURES: "/Features",
  URL_CREATE_FEATURES: "/Auth/Features/Create",
  URL_UPDATE_FEATURES: "/Auth/Features/Update",
  URL_DELETE_FEATURES: "/Auth/Features/Delete",

  /* Services */
  URL_INDEX_SERVICE: "/Services",

  /* Faqs */
  URL_INDEX_FAQS: "/Faqs",
  URL_CREATE_FAQS: "/Auth/Faqs/Create",
  URL_UPDATE_FAQS: "/Auth/Faqs/Update",
  URL_DELETE_FAQS: "/Auth/Faqs/Delete",

  /* Projects */
  URL_INDEX_PROJECTS: "/Projects",
  URL_CREATE_PROJECT: "/Auth/Projects/Create",
  URL_UPDATE_PROJECT: "/Auth/Projects/Update",
  URL_DELETE_PROJECT: "/Auth/Projects/Delete",

  /* Prices */
  URL_DELETE_PRICE: "/Auth/Prices/Delete",
  URL_CREATE_PRICE: "/Auth/Prices/Create",
  URL_UPDATE_PRICE: "/Auth/Prices/Update",
  
  /* Model Services */
  URL_INDEX_MSERVICE: "/Auth/ModelServices",
  URL_CREATE_MSERVICE: "/Auth/ModelServices/Create",
  URL_UPDATE_MSERVICE: "/Auth/ModelServices/Update",
  URL_DELETE_MSERVICE: "/Auth/ModelServices/Delete",

  /* Services */
  URL_CREATE_SERVICE: "/Auth/Services/Create",
  URL_UPDATE_SERVICE: "/Auth/Services/Update",
  URL_DELETE_SERVICE: "/Auth/Services/Delete",

  /* Clients */
  URL_INDEX_CLIENT: "/Clients",
  URL_CREATE_CLIENT: "/Auth/Clients/Create",
  URL_UPDATE_CLIENT: "/Auth/Clients/Update",
  URL_DELETE_CLIENT: "/Auth/Clients/Delete",

  /* Regions */
  URL_INDEX_REGIONS: "/Regions",

  /* Payments */
  URL_INDEX_PAYMENTS: "/Payments",
  URL_DELETE_PAYMENTS: "/Auth/Payments/Delete",

  /* Types */
  URL_INDEX_TYPES: "/Types",

  /* Methodologies */
  URL_INDEX_METHODOLOGIES: "/Methodologies",

  /* Promotions */
  URL_UPDATE_PROMOTION: "/Auth/Promotion/Update",

  /* Cache */
  URL_INDEX_CACHE: "/Cache",
  URL_CREATE_CACHE: "/Auth/Cache/Create",
}