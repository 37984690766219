import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  features: null,
  features_types: null,
}

export const featureSlice = createSlice({
  name: "features",
  initialState: initialState,
  reducers: {
    setFeatures: (state, action) => {
      state.features = action.payload;
    },
    setFeaturesTypes: (state, action) => {
      state.features_types = action.payload;
    },
  },
});


//Async functions
export const fetchFeaturesTypes = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setFeaturesTypes(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchFeatures = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setFeatures(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};




export const {setFeatures, setFeaturesTypes} = featureSlice.actions;

export const featureData = (state) => state.features;

export default featureSlice.reducer;