export const FORM = {
  FORM_LOGIN: {
    email: [null, "required"],
    password: [null, "required"],
    recordarme: [false, ""]
  },

  FORM_PROJECT: {
    id: [null, ""],
    template: [null, ""],
    title: ["", "required"],
    description: ["", ""],
    price: ["", "required"],
    oficial_price: ["", ""],
    link: ["", ""],
    status_terms_and_conditions: [null, ""],
    time_lapse: ["", "required"],
    users_id: [null, ""],
    terms_and_conditions_id: [null, ""],
    clients_id: [null, ""],
    types_id: [null, ""],
    status_id: [null, ""],
    methodology_id: [null, "required"],

    /* Opcion 1 */
    title_option: ["", ""],
    description_option: ["", ""],
    payment_types_id: [[], ""],
    payment_name: ["", ""],
    payment_config: ["cuota", ""],

    services: [[], ""],

    prices: [[], ""],
    price_premium: [null, ""],
    price_basico: [null, ""],

    features: [[], ""],
    faqs: [[], ""]
  },

  FORM_CLIENTS: {
    id: [null, ""],
    client_id: [null, ""],
    name: ["", "required"],
    email: ["", "required"],
    business_name: ["", "required"],
    description: [null, ""],
    regions_id: [null, "required"],
    currency: ["", ""],
    status_id:[null, ""],
  },

  FORM_PRICES: {
    id: [null, ""],
    name: [null, "required"],
    description: [null, ""],
    price: [null, "required"],
    premium: [null, ""],
    projects_id: [null, ""],
    status_id:[null, ""],
    premium: ["0", ""],
    time_lapse: ["", ""],

    // Payments methods
    payment_types_id: [[], ""],
    payment_name: ["", ""],
    payment_config: ["cuota", ""],
  },

  FORM_SERVICES: {
    id: [null, ""],
    id_proyect: [null, ""],
    name: [null, "required"],
    subname: [null, "required"],
    description: [null, ""],
    unid: [null, "required"],
    price: [null, "required"],
    duration_months: [null, "required"],
    unique: [false, ""],
    status_id: [null, ""],
    image: [null, "required"],
    image_url: [null, ""],
  },

  FORM_PROMOTIONS: {
    id: [null, ""],
    descont: [null, "required"],
    time_duration: [null, "required"],
    status_id:[null, "required"],
  },

  FORM_FEATURES: {
    id: [null, ""],
    name: [null, ""],
    description: [null, "required"],
    features_types_id: [null, ""],
    status_id:[null, ""],
    features_types_id: [null, ""]
  },
  
  FORM_FEATURES_TYPES: {
    id: [null, ""],
    name: [null, ""],
    description: [null, "required"],
    features_types_id: [null, ""],
    status_id:[null, ""],
    features_types_id: [null, ""]
  },

  FORM_FAQS: {
    question: [null, "required"],
    response: [null, "required"],
    status_id:[null, ""],
  },

  FORM_METHODOLOGY: {
    id: [null, "required"],
    name: [null, "required"],
    description:[null, "required"],
  }
}