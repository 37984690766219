import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  model_service: null,
  search: null
}

export const modelServiceSlice = createSlice({
  name: "model_service",
  initialState: initialState,
  reducers: {
    setModelService: (state, action) => {
      state.model_service = action.payload;
    },
    setModelServiceSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});


//Async functions
export const fetchModelService = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setModelService(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};




export const {setModelService, setModelServiceSearch} = modelServiceSlice.actions;

export const modelServiceData = (state) => state.model_service;

export default modelServiceSlice.reducer;