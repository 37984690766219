import { useEffect } from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alerts from "../../components/alerts/Alerts";
import BtnAccept from "../../components/forms/BtnAccept";
import CheckBox from "../../components/forms/CheckBox";
import Input from "../../components/forms/Input";
import clientAxios from "../../config/ClientAxios";
import { authContext } from "../../context/AuthContext";
import ApiRest from "../../hooks/useRest";
import { Validate } from "../../utils/ValidateForms";

const Login = () => {
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { FORM, URLs, VALIDATOR } = context;
  const { URL_LOGIN, URL_ME } = URLs;

  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM.FORM_LOGIN)));

  const [validation, setValidation] = useState(
    JSON.parse(JSON.stringify(VALIDATOR.VALIDATION_LOGIN))
  );

  const [loading, setLoading] = useState(false);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "login":
        {
          e.preventDefault();

          const validation = Validate(form);
          setValidation(validation);
          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          //create form
          let formData = new FormData();
          formData.append("email", form.email[0]);
          formData.append("password", form.password[0]);

          const response = await ApiRest({
            url: URL_LOGIN,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            localStorage.setItem("Session", JSON.stringify(response.data));

            Alerts({
              msg: "Alert",
              title: "Sesión iniciada",
              code: "201",
              type: "success",
            });

            return navigate("/dashboard/new/" + null);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key],
              code: "401",
              type: "error",
            });
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "recordarme": {
        let setCampo = form;
          setCampo[e.target.name][0] = e.target.checked;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
      } break;
      case "password":
      case "email":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await clientAxios.get(URL_ME);

      if (response.status == 200) {
        return navigate("/dashboard/project/" + null);
      }
    };

    fetch();
  }, []);
  return (
    <div class="container-md d-flex justify-content-center align-items-center min-vh-100">
      <form class="card-conteiner" style={{ minWidth: "450px" }}>
        <div class="mb-4 text-center">
          <svg
            width="180"
            height="33"
            viewBox="0 0 180 33"
            fill="#00214B"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_548_662)">
              <path
                d="M85.748 16.7542C85.748 15.8458 86.0173 14.9578 86.5219 14.2025C87.0264 13.4471 87.7435 12.8583 88.5826 12.5104C89.4217 12.1625 90.3451 12.0712 91.2361 12.248C92.1271 12.4248 92.9457 12.8617 93.5884 13.5036C94.2311 14.1455 94.6691 14.9635 94.847 15.8542C95.025 16.745 94.9349 17.6685 94.5881 18.508C94.2413 19.3476 93.6534 20.0655 92.8987 20.571C92.144 21.0765 91.2564 21.347 90.3481 21.3482C89.1291 21.3482 87.96 20.8643 87.0975 20.003C86.235 19.1416 85.7496 17.9731 85.748 16.7542Z"
                fill="#00214B"
              />
              <path
                d="M107.212 16.7545C107.212 15.8843 107.47 15.0336 107.953 14.31C108.437 13.5864 109.124 13.0224 109.928 12.6894C110.732 12.3564 111.617 12.2693 112.47 12.439C113.324 12.6088 114.108 13.0279 114.723 13.6432C115.339 14.2586 115.758 15.0426 115.927 15.8961C116.097 16.7496 116.01 17.6343 115.677 18.4383C115.344 19.2423 114.78 19.9295 114.056 20.413C113.333 20.8964 112.482 21.1545 111.612 21.1545C110.445 21.1545 109.326 20.6909 108.501 19.8658C107.675 19.0406 107.212 17.9214 107.212 16.7545Z"
                fill="#00214B"
              />
              <path
                d="M2.42403 13.2524C2.41739 14.6542 2.70065 16.0423 3.25603 17.3294C4.33373 19.8649 6.35046 21.8852 8.88403 22.9674C10.168 23.5154 11.5495 23.7979 12.9455 23.7979C14.3415 23.7979 15.7231 23.5154 17.007 22.9674C18.7978 22.1989 20.3432 20.9537 21.475 19.3674H24.349C23.7553 20.4902 22.9965 21.5176 22.098 22.4154C20.9134 23.5972 19.5149 24.543 17.977 25.2024C16.3888 25.8781 14.6805 26.2264 12.9545 26.2264C11.2285 26.2264 9.52027 25.8781 7.93203 25.2024C4.82469 23.8747 2.34922 21.3995 1.02103 18.2924C0.347239 16.7038 0 14.9959 0 13.2704C0 11.5448 0.347239 9.83695 1.02103 8.24839C2.35031 5.14175 4.82539 2.66667 7.93203 1.33739C9.52077 0.663626 11.2288 0.316406 12.9545 0.316406C14.6802 0.316406 16.3883 0.663626 17.977 1.33739C19.5147 1.99821 20.913 2.94459 22.098 4.12639C22.9967 5.02398 23.7555 6.05143 24.349 7.17439H21.44C21.1154 6.70759 20.7442 6.27503 20.332 5.88339C19.3843 4.90718 18.2548 4.12583 17.007 3.58339C15.7297 3.02289 14.3489 2.73705 12.954 2.74439C11.5544 2.73799 10.1686 3.02162 8.88403 3.57739C6.35631 4.65954 4.34133 6.67202 3.25603 9.19839C2.69944 10.4788 2.41607 11.8613 2.42403 13.2574"
                fill="#00214B"
              />
              <path
                d="M26.8428 1.14453H29.3369V9.59753C29.6211 9.22278 29.9528 8.88662 30.3239 8.59753C30.7174 8.29106 31.1477 8.03507 31.6048 7.83553C32.0792 7.62798 32.572 7.46559 33.0768 7.35053C33.5768 7.23633 34.088 7.17831 34.6008 7.17753C35.6381 7.17199 36.665 7.38458 37.6149 7.80153C39.48 8.60537 40.967 10.0923 41.7708 11.9575C42.1873 12.9072 42.3999 13.9336 42.3949 14.9705V15.0055L42.4288 25.3965H39.9359L39.9008 15.0055V14.9705C39.9061 14.2716 39.7708 13.5788 39.5029 12.9332C39.2351 12.2877 38.8403 11.7025 38.3418 11.2125C37.8497 10.7353 37.2733 10.3535 36.6418 10.0865C35.996 9.81023 35.3003 9.66932 34.5979 9.67253C33.9088 9.67109 33.2283 9.82489 32.6069 10.1225C31.9831 10.4137 31.4191 10.8186 30.9438 11.3165C30.47 11.8149 30.0836 12.3897 29.8009 13.0165C29.5144 13.6415 29.3667 14.3211 29.3678 15.0085V25.3925H26.8398L26.8428 1.14453Z"
                fill="#00214B"
              />
              <path
                d="M44.7119 2.60141C44.7201 2.27472 44.8406 1.96084 45.0533 1.71271C45.266 1.46459 45.5577 1.2974 45.8793 1.23937C46.2009 1.18134 46.5327 1.236 46.8186 1.39414C47.1046 1.55229 47.3273 1.80423 47.4491 2.10746C47.5709 2.4107 47.5844 2.74668 47.4873 3.05871C47.3902 3.37074 47.1885 3.63974 46.9161 3.82032C46.6438 4.00089 46.3175 4.082 45.9922 4.04996C45.667 4.01791 45.3628 3.87467 45.1309 3.64441C44.9945 3.50874 44.8872 3.34656 44.8159 3.16787C44.7445 2.98917 44.7105 2.79776 44.7159 2.60541M44.9229 8.07841H47.3479V25.3924H44.9229V8.07841Z"
                fill="#00214B"
              />
              <path
                d="M49.8408 8.07447H52.3348V9.63247C52.9319 8.85948 53.7116 8.24682 54.6038 7.84947C55.541 7.40346 56.5659 7.1725 57.6038 7.17347C58.9006 7.15633 60.1769 7.4979 61.2918 8.16047C62.3848 8.81538 63.3148 9.70985 64.0118 10.7765C64.7159 9.67969 65.6869 8.77958 66.8338 8.16047C68.0153 7.50504 69.3457 7.16511 70.6968 7.17347C71.7337 7.16842 72.7602 7.381 73.7098 7.79747C74.6335 8.1972 75.4738 8.76711 76.1868 9.47747C77.6418 10.9476 78.457 12.9331 78.4548 15.0015L78.4898 25.3925H75.9918L75.9568 15.0015C75.9619 14.2874 75.8207 13.5799 75.5418 12.9225C75.2749 12.291 74.8931 11.7146 74.4158 11.2225C73.9414 10.7364 73.3764 10.3477 72.7528 10.0785C72.1002 9.80522 71.3998 9.6645 70.6923 9.6645C69.9848 9.6645 69.2844 9.80522 68.6318 10.0785C68.0047 10.3504 67.4347 10.7386 66.9518 11.2225C66.4648 11.7101 66.0767 12.2874 65.8088 12.9225C65.5297 13.5798 65.3881 14.2873 65.3928 15.0015L65.4278 25.3925H62.9338L62.8988 15.0015C62.8597 14.2674 62.6904 13.5462 62.3988 12.8715C62.1276 12.2382 61.7399 11.6616 61.2558 11.1715C60.7842 10.6993 60.2256 10.323 59.6108 10.0635C58.9752 9.79529 58.2917 9.6592 57.6018 9.66347C56.9013 9.65933 56.2088 9.81315 55.5758 10.1135C54.9487 10.4074 54.3796 10.8118 53.8958 11.3075C53.4141 11.7995 53.0267 12.3757 52.7528 13.0075C52.4772 13.6353 52.3356 14.3138 52.3368 14.9995L52.3718 25.3905H49.8778L49.8408 8.07447Z"
                fill="#00214B"
              />
              <path
                d="M80.841 16.733C80.8322 15.4596 81.0858 14.1981 81.586 13.027C82.5546 10.7411 84.3742 8.92154 86.66 7.95303C87.8337 7.46126 89.0935 7.20801 90.366 7.20801C91.6386 7.20801 92.8984 7.46126 94.072 7.95303C96.3574 8.92234 98.1767 10.7417 99.146 13.027C99.6378 14.2007 99.8911 15.4605 99.8911 16.733C99.8911 18.0056 99.6378 19.2654 99.146 20.439C98.1767 22.7244 96.3574 24.5437 94.072 25.513C92.901 26.0135 91.6395 26.2674 90.366 26.259C88.9502 26.2335 87.5608 25.8708 86.313 25.201C85.076 24.6265 84.0214 23.722 83.265 22.587V32.287H80.841V16.733ZM83.265 16.733C83.2604 17.6793 83.4493 18.6164 83.82 19.487C84.5378 21.1994 85.8997 22.5612 87.612 23.279C88.4838 23.6453 89.4199 23.834 90.3655 23.834C91.3112 23.834 92.2473 23.6453 93.119 23.279C94.832 22.5617 96.1943 21.1998 96.912 19.487C97.2777 18.6149 97.4661 17.6787 97.4661 16.733C97.4661 15.7874 97.2777 14.8511 96.912 13.979C96.1943 12.2663 94.832 10.9043 93.119 10.187C92.2473 9.82072 91.3112 9.63203 90.3655 9.63203C89.4199 9.63203 88.4838 9.82072 87.612 10.187C85.8997 10.9048 84.5378 12.2666 83.82 13.979C83.4492 14.8496 83.2603 15.7868 83.265 16.733Z"
                fill="#00214B"
              />
              <path
                d="M102.072 16.6988C102.063 15.4254 102.317 14.1639 102.817 12.9928C103.787 10.7071 105.606 8.88729 107.892 7.91683C109.066 7.42638 110.326 7.17383 111.598 7.17383C112.87 7.17383 114.13 7.42638 115.304 7.91683C117.589 8.88777 119.408 10.7075 120.378 12.9928C120.877 14.1641 121.131 15.4256 121.122 16.6988V25.3928H118.698V23.0718L118.317 23.4528C117.452 24.3147 116.429 25.0025 115.304 25.4788C114.13 25.9706 112.871 26.2239 111.598 26.2239C110.325 26.2239 109.066 25.9706 107.892 25.4788C105.606 24.5099 103.786 22.6905 102.817 20.4048C102.317 19.2337 102.063 17.9722 102.072 16.6988ZM104.497 16.6988C104.493 17.6449 104.681 18.582 105.051 19.4528C105.77 21.165 107.132 22.5267 108.844 23.2448C109.716 23.6105 110.652 23.7988 111.597 23.7988C112.543 23.7988 113.479 23.6105 114.351 23.2448C115.198 22.8881 115.969 22.3709 116.62 21.7218C117.266 21.0743 117.784 20.3097 118.144 19.4688C118.467 18.6865 118.654 17.855 118.698 17.0098V16.6988C118.703 15.7527 118.514 14.8155 118.144 13.9448C117.425 12.2326 116.063 10.8707 114.351 10.1518C113.479 9.78618 112.543 9.59785 111.597 9.59785C110.652 9.59785 109.716 9.78618 108.844 10.1518C107.132 10.8707 105.77 12.2326 105.051 13.9448C104.681 14.8155 104.492 15.7527 104.497 16.6988Z"
                fill="#00214B"
              />
              <path
                d="M136.674 15.0012C136.679 14.2874 136.538 13.5802 136.259 12.9232C135.717 11.6429 134.698 10.6239 133.418 10.0822C132.773 9.80557 132.077 9.66432 131.375 9.66719C130.669 9.6648 129.971 9.81882 129.331 10.1182C128.696 10.4089 128.12 10.8136 127.631 11.3122C127.145 11.8101 126.752 12.3905 126.47 13.0262C126.187 13.6463 126.04 14.3196 126.037 15.0012L126.072 25.3922H123.612V8.07419H126.036V9.66719C126.288 9.27471 126.606 8.92865 126.976 8.64419C127.376 8.32678 127.812 8.05822 128.276 7.84419C128.758 7.62211 129.264 7.45441 129.783 7.34419C131.328 7.00609 132.94 7.16319 134.39 7.79319C136.261 8.59818 137.75 10.0931 138.547 11.9672C138.962 12.9237 139.174 13.9557 139.17 14.9982L139.205 25.3892H136.712L136.674 15.0012Z"
                fill="#00214B"
              />
              <path
                d="M143.74 16.6988C143.735 17.645 143.924 18.5821 144.294 19.4528C145.013 21.1647 146.374 22.5263 148.086 23.2448C148.958 23.6104 149.894 23.7988 150.84 23.7988C151.785 23.7988 152.721 23.6104 153.593 23.2448C154.441 22.8883 155.212 22.3711 155.863 21.7218C156.331 21.2504 156.727 20.7134 157.04 20.1278H159.712L159.612 20.4048C159.142 21.538 158.453 22.5678 157.586 23.4358C156.716 24.3044 155.687 24.9979 154.555 25.4788C153.382 25.9705 152.122 26.2238 150.85 26.2238C149.577 26.2238 148.318 25.9705 147.144 25.4788C144.858 24.51 143.039 22.6905 142.069 20.4048C141.577 19.2311 141.324 17.9713 141.324 16.6988C141.324 15.4263 141.577 14.1665 142.069 12.9928C143.039 10.7071 144.859 8.88725 147.144 7.91679C148.318 7.42636 149.578 7.17383 150.85 7.17383C152.122 7.17383 153.381 7.42636 154.555 7.91679C155.687 8.39798 156.716 9.09224 157.586 9.96179C158.453 10.8297 159.142 11.8596 159.612 12.9928L159.712 13.2338H157.012C156.713 12.6593 156.328 12.134 155.87 11.6758C155.219 11.0262 154.448 10.5087 153.6 10.1518C152.728 9.78614 151.792 9.59781 150.847 9.59781C149.901 9.59781 148.965 9.78614 148.093 10.1518C146.382 10.8712 145.02 12.2329 144.301 13.9448C143.93 14.8153 143.742 15.7526 143.747 16.6988"
                fill="#00214B"
              />
              <path
                d="M99.6901 7.55739C99.6901 7.55739 99.3481 7.39239 98.7481 7.12839C98.1481 6.86439 97.2861 6.50339 96.2481 6.11939C95.0737 5.68429 93.8708 5.33013 92.6481 5.05939C91.9694 4.91055 91.2825 4.8017 90.5911 4.73339C89.8881 4.66091 89.1801 4.64887 88.4751 4.69739C87.7803 4.73612 87.0924 4.85525 86.4251 5.05239C86.1 5.14764 85.78 5.25911 85.4661 5.38639C85.1551 5.51039 84.8661 5.67339 84.5721 5.81839C84.0267 6.14207 83.5116 6.51424 83.0331 6.93039C82.8161 7.14439 82.5871 7.34039 82.3921 7.55439L81.8261 8.18439C81.6571 8.39639 81.5101 8.61339 81.3551 8.81139C81.2043 8.99577 81.0673 9.19096 80.9451 9.39539C80.7121 9.77839 80.4691 10.0954 80.3251 10.3904C80.0061 10.9624 79.8101 11.2904 79.8101 11.2904C79.8101 11.2904 79.9001 10.9254 80.0821 10.2844C80.1968 9.89961 80.3351 9.52223 80.4961 9.15439C80.698 8.66809 80.9339 8.19659 81.2021 7.74339L81.7021 6.98539C81.8821 6.72339 82.1131 6.48539 82.3291 6.22639C82.5652 5.96868 82.8142 5.72303 83.0751 5.49039C83.3361 5.24239 83.6541 5.05039 83.9521 4.82039C84.6009 4.39454 85.3043 4.05817 86.0431 3.82039C86.8018 3.57894 87.5861 3.42691 88.3801 3.36739C89.1645 3.32818 89.9508 3.35762 90.7301 3.45539C91.4746 3.55254 92.2103 3.7087 92.9301 3.92239C93.5977 4.11267 94.2526 4.3448 94.8911 4.61739C95.4981 4.87439 96.0581 5.14739 96.5591 5.41739C97.37 5.85067 98.1472 6.3444 98.8841 6.89439C99.4141 7.29439 99.6931 7.54939 99.6931 7.54939"
                fill="#00214B"
              />
              <path
                d="M122.012 11.2901C122.012 11.2901 121.922 10.8901 121.785 10.2031C121.652 9.87813 121.53 9.48813 121.338 9.06413C121.103 8.59406 120.824 8.14739 120.504 7.73013C120.339 7.48312 120.145 7.25625 119.927 7.05413C119.7 6.84613 119.506 6.59413 119.247 6.39713L118.447 5.79713L117.547 5.28313L117.316 5.15213L117.066 5.05214L116.566 4.85213C116.395 4.78913 116.231 4.71213 116.056 4.65913L115.518 4.52514C114.792 4.336 114.049 4.21613 113.3 4.16714H112.16C111.97 4.16714 111.781 4.15614 111.592 4.17714L111.028 4.24913C110.654 4.30113 110.282 4.34213 109.912 4.38113L108.835 4.62914C108.149 4.77784 107.473 4.97228 106.812 5.21114C106.493 5.31914 106.175 5.41114 105.88 5.52414L105.029 5.88113C104.49 6.12413 103.962 6.29313 103.529 6.51913L102.399 7.07813C101.765 7.38313 101.392 7.54813 101.392 7.54813C101.392 7.54813 101.7 7.29113 102.258 6.85413C102.537 6.63713 102.877 6.37113 103.276 6.07813C103.73 5.75915 104.201 5.46472 104.687 5.19613L105.512 4.71313C105.802 4.55113 106.125 4.43413 106.446 4.28713L107.446 3.85114C107.799 3.70781 108.161 3.58985 108.53 3.49813L109.668 3.16613C110.063 3.09413 110.468 3.03213 110.874 2.97413L111.487 2.88513C111.692 2.85813 111.903 2.86913 112.112 2.85913L113.374 2.83813C114.215 2.90834 115.048 3.05869 115.86 3.28713L116.46 3.45613C116.654 3.52813 116.84 3.62513 117.028 3.70613C117.216 3.78713 117.401 3.87814 117.585 3.96214L117.859 4.09013L118.113 4.25313C118.447 4.47413 118.786 4.67514 119.09 4.90614L119.921 5.66613C120.166 5.92213 120.394 6.19298 120.605 6.47713C120.813 6.73333 120.992 7.01129 121.139 7.30613C121.426 7.80051 121.651 8.32852 121.809 8.87814C121.912 9.3047 121.991 9.73672 122.045 10.1721C122.03 10.8771 122.01 11.2811 122.01 11.2811"
                fill="#00214B"
              />
              <path
                d="M163.067 16.681C163.062 17.6268 163.251 18.5637 163.621 19.434C164.34 21.1462 165.702 22.5081 167.414 23.227C168.286 23.5933 169.222 23.782 170.168 23.782C171.113 23.782 172.049 23.5933 172.921 23.227C173.769 22.8703 174.539 22.3527 175.19 21.703C175.659 21.2327 176.055 20.6959 176.367 20.11H179.034L178.934 20.387C178.463 21.5201 177.774 22.5498 176.907 23.418C176.037 24.2867 175.009 24.9803 173.877 25.461C172.703 25.9534 171.443 26.207 170.171 26.207C168.898 26.207 167.638 25.9534 166.464 25.461C164.179 24.4917 162.359 22.6724 161.39 20.387C160.899 19.2131 160.646 17.9531 160.646 16.6805C160.646 15.4079 160.899 14.148 161.39 12.974C162.359 10.6887 164.179 8.86957 166.464 7.90102C167.638 7.40863 168.898 7.15503 170.171 7.15503C171.443 7.15503 172.703 7.40863 173.877 7.90102C175.008 8.38254 176.036 9.07563 176.907 9.94302C177.774 10.8115 178.463 11.8412 178.934 12.974C179.348 13.9172 179.594 14.9253 179.661 15.953H163.101C163.079 16.209 163.067 16.453 163.067 16.681ZM175.19 11.658C174.538 11.0093 173.768 10.4919 172.921 10.134C172.049 9.76837 171.113 9.58004 170.168 9.58004C169.222 9.58004 168.286 9.76837 167.414 10.134C165.841 10.7975 164.558 12.0017 163.795 13.529H176.506C176.164 12.8428 175.72 12.212 175.19 11.658Z"
                fill="#00214B"
              />
              <path
                d="M168.442 4.69609L171.722 -0.00390625H175.612L170.57 4.69609H168.442Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_548_662">
                <rect width="179.658" height="32.285" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <Input
          onChange={onChange}
          label={""}
          name={"email"}
          type={"email"}
          value={form.email[0]}
          validate={validation.email}
        />
        <Input
          onChange={onChange}
          label={""}
          name={"password"}
          type={"password"}
          value={form.password[0]}
          validate={validation.password}
        />
        <div className="mt-3 mb-3">
        <CheckBox
          onChange={onChange}
          label={"Recordarme"}
          name={"recordarme"}
          type={"checkbox"}
          value={form.recordarme[0]}
          defaultValue={false}
          validate={{ msg: "", validation: "" }}
        />
        </div>
        <div className="mt-3 text-center">
          <BtnAccept
            onClick={onClick}
            name={"login"}
            value={"Login"}
            loading={loading}
            loadingMsg={"Iniciando sesión..."}
          />
        </div>
      </form>
    </div>
  );
};

export default Login;
