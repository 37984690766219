import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import {
  featureData,
  fetchFeaturesTypes,
} from "../../../slices/features/Index";
import { ConcatFeatures } from "../../../utils/ConcatFeatures";
import ModalFeature from "./modals/ModalFeature";
import ModalFeatureType from "./modals/ModalFeatureType";

const Features = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { URLs, FORM, VALIDATOR } = context;

  const { URL_INDEX_FEATURES_TYPES, URL_DELETE_FEATURES } = URLs;
  const { FORM_FEATURES } = FORM;
  const { VALIDATION_FEATURES } = VALIDATOR;

  const _features = useSelector(featureData);
  const features_types = _features.features_types;

  const [modalFeature, setModalFeature] = useState(false);
  const [modalFeatureType, setModalFeatureType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(null);
  const [form, setForm] = useState({ ...FORM_FEATURES });
  const [validate, setValidate] = useState({ ...VALIDATION_FEATURES });

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "edit-feature":
        {
          setModalFeature(value.type);
          setSelect(value.feat);
        }
        break;
      case "delete-feature":
        {
          let formData = new FormData();
          formData.append("id", value.id);

          const response = await ApiRest({
            url: URL_DELETE_FEATURES,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            let params = {
              url: URL_INDEX_FEATURES_TYPES,
            };

            dispatch(fetchFeaturesTypes(params));

            //cerrar modal
            return Alerts({
              msg: "Alert",
              title: "Eliminando característica...",
              code: "201",
              type: "success",
            });
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
    }
  };

  useEffect(() => {
    if (!features_types) {
      let params = {
        url: URL_INDEX_FEATURES_TYPES,
      };

      dispatch(fetchFeaturesTypes(params));
    }
  }, []);
  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-3">
        <h3>CARACTERÍSTICAS</h3>
        <div>
          <label class="btn btn-primary d-flex">
            <img src="/assets/img/icons/icon-plus.svg" class="me-2" />
            AÑADIR CARACTERÍSTICA
            <button
              className="d-none"
              name="modalService"
              onClick={() => setModalFeatureType(true)}
            ></button>
          </label>
        </div>
      </div>

      <div class="row">
        {features_types
          ? features_types.map((type) => (
              <div class="col-md-6  mb-3">
                <div className="card-conteiner">
                  <h4>{type.name}</h4>

                  {type.features.map((feat, index) => (
                    <div class="mb-4">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="m-0" dangerouslySetInnerHTML={{ __html: feat.description }}></div>
                        <div>
                          <label className="btn btn-secondary me-1">
                            <img
                              src="/assets/img/icons/icon-trash.svg"
                              alt=""
                            />
                            <button
                              className="d-none"
                              name="delete-feature"
                              onClick={(event) => onClick(event, feat)}
                            ></button>
                          </label>
                          <label class="btn btn-primary">
                            <img src="/assets/img/icons/icon-edit.svg" />
                            <button
                              className="d-none"
                              name="edit-feature"
                              onClick={(event) =>
                                onClick(event, { feat: feat, type: type })
                              }
                            ></button>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}

                  <label className="btn btn-primary d-flex mt-3">
                    <img src="/assets/img/icons/icon-plus.svg" class="me-2" />
                    CARACTERÍSTICA
                    <button
                      class="d-none"
                      name=""
                      onClick={() => setModalFeature(type)}
                    ></button>
                  </label>
                </div>
              </div>
            ))
          : ""}
      </div>

      {/* Modals Features */}

      {modalFeature ? (
        <ModalFeature
          modal={modalFeature}
          setModal={setModalFeature}
          validate={validate}
          setValidate={setValidate}
          setSelect={setSelect}
          select={select}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        ""
      )}

      {modalFeatureType ? (
        <ModalFeatureType
          modal={modalFeatureType}
          setModal={setModalFeatureType}
          setSelect={setSelect}
          select={select}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Features;
