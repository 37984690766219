const Pagination = ({
  length,
  pagination,

  onClick,
}) => {


  return (
    <div className="border-0 d-flex justify-content-end w-100">
    { 
      pagination.type == 1 ? (
      <tr>
        <div colSpan={length - 1} className="border-0 me-2">
          {"Resultado 1 a " +
            pagination.last_page +
            " de " +
            pagination.last_page +
            " Entradas"}
        </div>

        <th className="border-0">
          <div className="d-flex justify-content-end w-100 my-2">
            <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
              <ul class="pagination bg-transparent">
                <li class="page-item">
                  <button
                    className="btn btn-outline-warning me-1 fs-8"
                    name="page"
                    onClick={(event) =>
                      onClick(event, pagination.prev_page_url)
                    }
                    disabled={pagination.current_page == 1 ? true : false}
                  >
                    Anterior
                  </button>
                </li>

                {pagination.links.map((item, index) => {
                  if (
                    (item.label !== "&laquo; Previous" &&
                      item.label !== "Next &raquo;" &&
                      item.active == true) ||
                    item.label == 1 ||
                    item.label == pagination.current_page - 1 ||
                    item.label == pagination.current_page + 1 ||
                    item.label == pagination.current_page + 2 ||
                    item.label == pagination.per_page ||
                    item.label == pagination.last_page
                  ) {
                    return (
                      <li
                        style={{ borderRadius: "5px" }}
                        className={item.active == true ? "fs-8" : "fs-8"}
                      >
                        <button
                          style={{ borderRadius: "5px" }}
                          className={
                            item.active == true
                              ? "btn btn-warning me-1 fs-8"
                              : "btn btn-outline-warning me-1 fs-8"
                          }
                          name="page"
                          onClick={(event) => onClick(event, item.url)}
                        >
                          {item.label}
                        </button>
                      </li>
                    );
                  }
                })}
                <li class="page-item">
                  <button
                    className="btn btn-outline-warning me-1 fs-8 "
                    name="page"
                    onClick={(event) =>
                      onClick(event, pagination.next_page_url)
                    }
                    disabled={
                      pagination.current_page == pagination.last_page
                        ? true
                        : false
                    }
                  >
                    Siguiente
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </th>
      </tr>
    ) : pagination.type == 2 ? (
      <tr>
        <th colSpan={length} className="border-0">
          <label className={pagination.current_page == pagination.last_page ? "btn btn-secondary w-100 d-flex justify-content-center disabled" : "btn btn-secondary w-100 d-flex justify-content-center" }>
            Ver mas
            <button
              className="d-none"
              name="page"
              onClick={(event) => onClick(event, pagination.next_page_url)}
            ></button>
          </label>
        </th>
      </tr>
    ) : (
      ""
    ) }
  </div>
  )
}

export default Pagination