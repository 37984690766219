const Spinner = ({text}) => {
  return (
    <div className="text-center">
      <div className="d-flex justify-content-center align-items-center mb-3">
      <div class="spinner-grow me-1 bg-miel" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow me-1 bg-miel" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow me-1 bg-miel" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      </div>
      <div className="">{text}</div>
    </div>
  );
};

export default Spinner;
