import './App.css';
import StoreProvider from './providers/StoreProvider';
//Router
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
//Context
import { AuthProvider } from './context/AuthContext';
import Login from './pages/auth/Login';
import Sidebar from './pages/dashboard/sidebar/Index';
import Project from './pages/dashboard/projects/Index';
import Features from './pages/dashboard/features/Index';
import Faqs from './pages/dashboard/faqs/Index';
import Clients from './pages/dashboard/clients/Index';
import Services from './pages/dashboard/services';

function App() {
  return (
    
    <StoreProvider>
      <AuthProvider>
        <Router>
          <Routes>

            {/* Routes Authenticated */}

            <Route exact path='/login' element={<Login />} />
            <Route exact path='/' element={<Login/>} />

            {/* Routes Dashboard / Administrator */}

            <Route exact path='/dashboard/:type/:id' element={<Sidebar><Project/></Sidebar>} />

            <Route exact path='/dashboard/features' element={<Sidebar><Features/></Sidebar>} />
            
            <Route exact path='/dashboard/clients' element={<Sidebar><Clients/></Sidebar>} />

            <Route exact path='/dashboard/clients/details/:id' element={<Sidebar></Sidebar>} />

            <Route exact path='/dashboard/faqs' element={<Sidebar><Faqs/></Sidebar>} />

            <Route exact path='/dashboard/services' element={<Sidebar><Services/></Sidebar>} />

            {/* Routes Test */}

          </Routes>
        </Router>
      </AuthProvider>
      </StoreProvider>
  );
}

export default App;
