import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alerts from "../components/alerts/Alerts";
import clientAxios from "../config/ClientAxios";
import { authContext } from "../context/AuthContext";
import { setLogout } from "../slices/users/Index";

export const Logout = async (navigate, dispatch, url) => {
  const response = await clientAxios.get(url);
  localStorage.clear();
  dispatch(setLogout());
  
  Alerts({
    msg: "OK",
    title: "Cerrando sesión.",
    code: "201",
    type: "success",
  });

  return navigate('/login') 


}