export const AttachServices = (service) => {
  
  let form = {
    id: [service.id, ""],
    id_proyect: [service.projects_id, ""],
    name: [service.name, "required"],
    subname: [service.subname, "required"],
    description: [service.description, ""],
    unid: [service.unid, "required"],
    price: [service.price, "required"],
    duration_months: [service.duration_months, "required"],
    unique: [service.unique == 0? false : true, ""],
    status_id: [service.status_id, ""],
    image: [service.image, ""],
    image_url: [service.image, ""],
  }

  return form
}