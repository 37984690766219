import CheckBox from "../../../../components/forms/CheckBox";
import { AttachPayments } from "../../../../utils/AttachPayments";

const FormMethodology = ({ data, form, setForm, validate, select }) => {
  const onChange = (e) => {
    switch (e.target.name) {
      case "methodology_id":
        let setCampo = form;
        setCampo[e.target.name][0] = e.target.value;

        setForm({
          ...form,
          [e.target.name]: setCampo[e.target.name],
        });
        break;
    }
  };

  return (
    <div class="card-conteiner">
      <h4 class="mb-4">Metodologia de trabajo</h4>
      <form action="">
        {data.map((method) => (
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="methodology_id"
              value={method.id}
              onChange={(event) => onChange(event)}
              checked={form.methodology_id[0] !== null
                ? form.methodology_id[0] == method.id
                ? true : false
                : select
                ? select.methodology_id == method.id 
                ? true : false
                : false}
            />
            <label class="form-check-label fs-5" for="flexRadioDefault1">
              {method.name}
            </label>
          </div>
        ))}
      </form>
      {validate.validate ? (
        <div className="text-danger">{validate.msg}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FormMethodology;
