import Input from "../../../../components/forms/Input";

const FormPromotion = ({ form, setForm, validate, select }) => {
  const onChange = (e) => {
    switch (e.target.name) {
      case "time_duration":
      case "descont":
        {
            let setCampo = form;
            setCampo[e.target.name][0] = e.target.value;
  
            setForm({
              ...form,
              [e.target.name]: setCampo[e.target.name],
            });
        }
        break;
    }
  };
  return (
    <div class="card-conteiner mb-4">
      <h4 class="mb-4">Promoción</h4>
      <form class="row">
        <div class="col-md-6 mb-4 mb-md-0">
          <Input
            onChange={onChange}
            label={""}
            name={"descont"}
            type={"number"}
            placeholder={"% de descuento"}
            value={form.descont[0] !== null
              ? form.descont[0]
              : select
              ? select.promotions.length > 0 ? select.promotions[0].descont
              : "" : ""}
            validate={validate.descont}
          />
        </div>
        <div class="col-md-6">
          <Input
            onChange={onChange}
            label={""}
            name={"time_duration"}
            type={"number"}
            placeholder={"Horas de duración"}
            value={ form.time_duration[0] !== null
              ?  form.time_duration[0]
              : select
              ? select.promotions.length > 0 ? select.promotions[0].time_duration
              : "" : ""}
            validate={validate.time_duration}
          />
        </div>
      </form>
    </div>
  );
};

export default FormPromotion;
