export const AttachPromotion = (promotion) => {
  let form = {
    id: [promotion.id, ""],
    id_project: [promotion.projects_id, ""],
    descont: [promotion.descont, "required"],
    time_duration: [promotion.time_duration, "required"],
    status_id: [promotion.status_id, "required"],
  };

  return form;
};
