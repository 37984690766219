const Thead = ({campos}) => {



  return (
    <thead>
      <tr>
      {
        campos.map((campo) => (
          <th className="p-1">
            <div className={campo.class}>
            <div className="p-2">{campo.name}</div>
            {
            campo.order
            ? <label className="btn text-end">
              <img className="btn border-0 p-0 m-0 opacity-25" style={{width: "10px"}} src="/assets/images/arrow-down.svg" alt="" />
              <img className="btn border-0 p-0 m-0" style={{width: "10px"}} src="/assets/images/arrow-up.svg" alt="" />
              <button 
              className="d-none"
              name="order"
              
              ></button>
            </label>
            : ""
            }
            </div>
          </th>
        ))
      }
      </tr>
    </thead>
  )
}

export default Thead