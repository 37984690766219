const FormRegion = ({
  select,
  setFormClient,
  formClient,
  validate,
  regions,
  onClick
}) => {
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "regions_id":
        {
          let setCampo = formClient;
          setCampo[e.target.name][0] = e.target.value;

          setFormClient({
            ...formClient,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
    }
  };
  return (
    <div class="col-md-6 mb-2 d-flex justify-content-between justify-content-md-end">
      {validate.validate ? (
        <div className="text-danger me-3">{validate.msg}</div>
      ) : (
        ""
      )}
      <div
        class="btn-group me-4"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        {regions.map((r, index) => (
          <>
            <input
              type="radio"
              class="btn-check"
              id={"btnradio1_" + index}
              autocomplete="off"
              name="regions_id"
              onChange={(event) => onChange(event, r)}
              value={r.id}
              checked={
                formClient.regions_id[0] !== null
                  ? formClient.regions_id[0] == r.id
                  : select
                  ? select.clients.regions_id == r.id
                    ? true
                    : false
                  : formClient.regions_id[0]
              }
              disabled={select ? true : false}
            />
            <label class="btn btn-outline-primary" for={"btnradio1_" + index}>
              {r.name}
            </label>
          </>
        ))}
      </div>
      <div class="dropdown">
        <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        ></button>
        <ul class="dropdown-menu">
          <li>
            <label className="">
              <span class="dropdown-item" >
                Guardar propuesta
              </span>
              <button 
              className="d-none" 
              name="save"
              onClick={(event) => onClick(event)}
              ></button>
            </label>
          </li>
          <li>
            <label className="">
              <span class="dropdown-item" >
                Guardar cambios
              </span>
              <button 
              className="d-none" 
              name="apply"
              onClick={(event) => onClick(event)}
              ></button>
            </label>
          </li>
          <li>
            <label className="">
              <span class="dropdown-item" >
                Duplicar propuesta
              </span>
              <button 
              className="d-none" 
              name="double"
              onClick={(event) => onClick(event)}
              ></button>
            </label>
          </li>
          <li>
            <label className="">
              <span class="dropdown-item">
                Eliminar propuesta
              </span>
              <button 
              className="d-none" 
              name="delete"
              onClick={(event) => onClick(event)}
              ></button>
            </label>
          </li>
          <li>
            <label className="">
              <span class="dropdown-item">
                Guardar como plantilla
              </span>
              <button 
              className="d-none" 
              name="save_template"
              onClick={(event) => onClick(event)}
              ></button>
            </label>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FormRegion;
