const CardPrice = ({ price, onClick, premium, id, data }) => {
  return (
    <div class="card-conteiner h-100">
      <div className="d-flex justify-content-between mb-3">
        <h4 class="mb-4">
          {price.name[0]}
        </h4>

        <div className="d-flex">
          <label className="btn btn-secondary me-1">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_548_616)">
                <path
                  d="M22.7984 4.80035H16.7988V2.79648C16.7707 2.02832 16.4391 1.30266 15.8768 0.778575C15.3145 0.254491 14.5673 -0.0252491 13.799 0.00067745H10.1993C9.43105 -0.0252491 8.68388 0.254491 8.12155 0.778575C7.55923 1.30266 7.22765 2.02832 7.1995 2.79648V4.80035H1.19992C0.881679 4.80035 0.576476 4.92677 0.351448 5.15179C0.126419 5.37682 0 5.68203 0 6.00026C0 6.3185 0.126419 6.62371 0.351448 6.84873C0.576476 7.07376 0.881679 7.20018 1.19992 7.20018H2.39983V20.3993C2.39983 21.354 2.77909 22.2696 3.45418 22.9447C4.12926 23.6198 5.04487 23.999 5.99959 23.999H17.9988C18.9535 23.999 19.8691 23.6198 20.5442 22.9447C21.2193 22.2696 21.5985 21.354 21.5985 20.3993V7.20018H22.7984C23.1167 7.20018 23.4219 7.07376 23.6469 6.84873C23.8719 6.62371 23.9983 6.3185 23.9983 6.00026C23.9983 5.68203 23.8719 5.37682 23.6469 5.15179C23.4219 4.92677 23.1167 4.80035 22.7984 4.80035ZM9.59934 2.79648C9.59934 2.6045 9.85132 2.40051 10.1993 2.40051H13.799C14.147 2.40051 14.399 2.6045 14.399 2.79648V4.80035H9.59934V2.79648ZM19.1987 20.3993C19.1987 20.7175 19.0723 21.0227 18.8472 21.2477C18.6222 21.4728 18.317 21.5992 17.9988 21.5992H5.99959C5.68135 21.5992 5.37614 21.4728 5.15112 21.2477C4.92609 21.0227 4.79967 20.7175 4.79967 20.3993V7.20018H19.1987V20.3993Z"
                  fill="#00214B"
                />
                <path
                  d="M9 17C9.26522 17 9.51957 16.8946 9.70711 16.7071C9.89464 16.5196 10 16.2652 10 16V12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12V16C8 16.2652 8.10536 16.5196 8.29289 16.7071C8.48043 16.8946 8.73478 17 9 17ZM15 17C15.2652 17 15.5196 16.8946 15.7071 16.7071C15.8946 16.5196 16 16.2652 16 16V12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11C14.7348 11 14.4804 11.1054 14.2929 11.2929C14.1054 11.4804 14 11.7348 14 12V16C14 16.2652 14.1054 16.5196 14.2929 16.7071C14.4804 16.8946 14.7348 17 15 17Z"
                  fill="#00214B"
                />
              </g>
              <defs>
                <clipPath id="clip0_548_616">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <button
              class="d-none"
              name="delete-price"
              onClick={(event) => onClick(event, price)}
            ></button>
          </label>

          <label class="btn btn-primary">
            <img src="/assets/img/icons/icon-edit.svg" />
            <button
              className="d-none"
              name="edit-price"
              onClick={(event) => onClick(event, price)}
            ></button>
          </label>
        </div>
      </div>
      <div class="d-flex  align-items-center mb-2">
        <h3 class="fc-celeste mb-0">{price.price[0]}</h3>
        <p class="fc-celeste mb-0 ms-2">USD</p>
      </div>
      <div dangerouslySetInnerHTML={{ __html: price.description[0] }}></div>
      <div class="d-flex align-items-center mb-3">
        <ul class="list-group px-0 mx-0">
          {
            data.map((d, index) => {
              if(price.payment_types_id[0].indexOf(String(d.id)) >= 0){
                return (
                  <li class="list-group-item border-0 px-0 mx-0">{d.name}</li>
                )
              }
            })
          }
        </ul>
      </div>
    </div>
  );
};

export default CardPrice;
