import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import Spinner from "../../../components/loads/Spinner";
import Pagination from "../../../components/paginations/Pagination";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { faqData, fetchFaqs } from "../../../slices/faqs/Index";
import CardFaqs from "./cards/CardFaqs";
import ModalFaqs from "./modals/ModalFaqs";

const Faqs = () => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { URLs, FORM, VALIDATOR } = context;

  const { URL_INDEX_FAQS, URL_DELETE_FAQS } = URLs;
  const { FORM_FAQS } = FORM;
  const { VALIDATION_FAQS } = VALIDATOR;

  const _faqs = useSelector(faqData);
  const faqs = _faqs.faqs;

  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM_FAQS)));
  const [validate, setValidate] = useState(JSON.parse(JSON.stringify(VALIDATION_FAQS)));

  const [select, setSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalFaqs, setModalFaqs] = useState(false);

  
  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = faqs? faqs : {
    current_page: 0,
    first_page_url: 0,
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  };

  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "page":
      {
        let params = {
          url: value,
          status: 1
        }

        dispatch(fetchFaqs(params));
      }
      break;
      case "edit-faqs":{
        setSelect(value)
        setModalFaqs(true);
      } break;
      case "delete-faqs":{
        let formData = new FormData();
        formData.append('id', value.id);

        const response = await ApiRest({
          url: URL_DELETE_FAQS,
          formData: formData,
          setLoading: setLoading,
          method: "post",
        });

        //Error
        if (response.status == 201 || response.status == 200) {
          
          let params = {
            url: URL_INDEX_FAQS,
            status: 1
          };

          dispatch(fetchFaqs(params));

          //cerrar modal
          return Alerts({
            msg: "Alert",
            title: "Eliminando pregunta",
            code: "201",
            type: "success",
          });
        }

        Object.keys(response.data).map((key) => {
          return Alerts({
            msg: "Alert",
            title: response.data[key][0],
            code: "401",
            type: "error",
          });
        });
      } break;
    }
  }

  useEffect(() => {
    if(!faqs){
      let params = {
        url: URL_INDEX_FAQS,
        status: 1
      }
        dispatch(fetchFaqs(params))
    }
  },[])

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-3">
        <h3>FAQS</h3>
        <div>
          <label class="btn btn-primary d-flex">
            <img src="/assets/img/icons/icon-plus.svg" class="me-2" />
            AÑADIR FAQS
            <button
              className="d-none"
              name="modalService"
              onClick={(event) => setModalFaqs(true)}
            ></button>
          </label>
        </div>
      </div>

      <div class="row card-conteiner">

          {
            faqs
            ? faqs.data.length > 0 
            ? faqs.data.map((faq, index) => (
              <CardFaqs data={faq} index={index} onClick={onClick} />
            ))
            : <div className="text-center fs-4">No existen preguntas disponibles</div>
            : <div> <Spinner /> </div>
          }

          <div className="col-md-12 ">
            <Pagination pagination={pagination} onClick={onClick} />
          </div>

      </div>

      {
        modalFaqs
        ? <ModalFaqs 
        modal={modalFaqs}
        setModal={setModalFaqs}
        form={form}
        setForm={setForm}
        validate={validate}
        setValidate={setValidate}
        setSelect={setSelect}
        select={select}
        loading={loading}
        setLoading={setLoading}
        />
        : ""
      }
    </div>
  );
};

export default Faqs;
