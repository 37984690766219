import { createContext } from "react";
import { useDispatch } from "react-redux";
import { FORM } from "../const/forms";
import { HARDDATA } from "../const/HardData";
import { URLs } from "../const/urls";
import { VALIDATOR } from "../const/validators";

export const authContext = createContext(); 

export const AuthProvider = ({children}) => {
const dispatch = useDispatch();



return (
  <authContext.Provider value={{
    URLs: URLs,
    FORM: FORM,
    VALIDATOR: VALIDATOR,
    HARDDATA: HARDDATA
  }}  > 
    {children}
  </authContext.Provider>
)
}

