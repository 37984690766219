import { useEffect } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alerts from "../../../../components/alerts/Alerts";
import Table from "../../../../components/tables/Table";
import { authContext } from "../../../../context/AuthContext";
import ApiRest from "../../../../hooks/useRest";
import {
  fetchModelService,
  modelServiceData,
} from "../../../../slices/model_services/Index";

const ModalListServices = ({
  setModal,
  form,
  setForm,
  validate,
  setLoading,
  loading,
  setSelect,
  select,
  setSteps,
  steps,
  id,
}) => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { FORM, VALIDATOR, URLs } = context;

  const { URL_INDEX_MSERVICE, URL_CREATE_SERVICE, URL_UPDATE_SERVICE } = URLs;
  const { FORM_SERVICES } = FORM;
  const { VALIDATION_SERVICES } = VALIDATOR;

  const _model_services = useSelector(modelServiceData);
  const model_services = _model_services.model_service;

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "page": {
        let params = {
          url: value,
          status_id: 1
        }

        dispatch(fetchModelService(params))
      } break;
      case "añadir":
        {
          console.log(value);

          /* Save service data */
          if (id) {
            let formData = new FormData();

            formData.append("projects_id", id);
            formData.append("name", value.name);
            formData.append("subname", value.subname);
            formData.append("description", value.description);
            formData.append("unid", value.unid);
            formData.append("price", value.price);
            formData.append("duration_months", value.duration_months);
            formData.append("unique", value.unique);
            formData.append("image_url", value.image);

            const response = await ApiRest({
              url: URL_CREATE_SERVICE,
              formData: formData,
              setLoading: setLoading,
              method: "post",
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              setSteps(steps + 1);

              //cerrar modal
              return Alerts({
                msg: "Alert",
                title: "Nuevo servicio creado",
                code: "201",
                type: "success",
              });
            }

            Object.keys(response.data).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            });
          }

          let formService = {
            id: [value.id, ""],
            id_proyect: [id, ""],
            name: [value.name, "required"],
            subname: [value.subname, "required"],
            description: [value.description, ""],
            unid: [value.unid, "required"],
            price: [value.price, "required"],
            duration_months: [value.duration_months, "required"],
            unique: [value.unique, ""],
            status_id: [value.status_id, ""],
            image: ["", "required"],
            image_url: [value.image, ""],
          };

          let setCampo = form;
          setCampo["services"][0] = [...setCampo["services"][0], formService];

          setForm({
            ...form,
            ["services"]: setCampo["services"],
          });

          //return setModal(false);
        }
        break;
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search" : {
        //
        let params = {
          url: URL_INDEX_MSERVICE,
          search: e.target.value,
          status_id: 1,
        };
  
        if (
          e.target.value.length > 0
        ) {
          dispatch(fetchModelService(params));
        }
  
        if (e.target.value.trim() == "") {
          let params = {
            url: URL_INDEX_MSERVICE,
            //search: e.target.value,
            status_id: 1,
          };
          dispatch(fetchModelService(params))
        }
        } break;
    }
  };

  /************************************************************ */
  const campos = [
    {
      name: "ID",
      order: true,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "NOMBRE",
      order: true,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "PRECIO",
      order: true,
      class: "border-0 d-flex justify-content-between align-items-center",
    },
    {
      name: "ACCIONES",
      order: false,
      class: "border-0 d-flex justify-content-center align-items-center",
    },
  ];
  const order = ["id", "name", "price"];
  const contents = model_services ? model_services.data : [];
  const actions = [
    {
      title: "Añadir",
      name: "añadir",
      icon: '<img src="/assets/img/icons/icon-plus.svg"  className="" />',
      class: "btn btn-primary me-1 p-2",
      id: "añadir",
    },
  ];
  const {
    current_page,
    first_page_url,
    from,
    last_page,
    last_page_url,
    links,
    next_page_url,
    path,
    per_page,
    prev_page_url,
    to,
    total,
  } = model_services
    ? model_services
    : {
        current_page: 0,
        first_page_url: 0,
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };
  const pagination = {
    type: 1,
    current_page: current_page,
    first_page_url: first_page_url,
    from: from,
    last_page: last_page,
    last_page_url: last_page_url,
    links: links,
    next_page_url: next_page_url,
    path: path,
    per_page: per_page,
    prev_page_url: prev_page_url,
    to: to,
    total: total,
  };
  /************************************************************ */

  useEffect(() => {
    if (!model_services) {
      let params = {
        url: URL_INDEX_MSERVICE,
        status_id: 1,
      };

      dispatch(fetchModelService(params));
    }
  }, []);
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <div className="modal-header p-4">
            <h1 className="modal-title fs-3" id="nueva-ordenModalLabel">
              Añadir servicio opcional
            </h1>
            <label className="btn btn-close">
              <button
                type="button"
                className="d-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                name="close"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>
          <div className="modal-body p-5">
            <div class="row">
              <div className="col-md-12">
                <div class="card-body">
                  <Table
                    campos={campos}
                    order={order}
                    contents={contents}
                    actions={actions}
                    pagination={pagination}
                    onClick={onClick}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalListServices;
