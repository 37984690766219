import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const InputCKEditor = ({ onChange, onBlur, onFocus, onReady, data, name }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        console.log("Editor is ready to use!", editor);
      }}
      onChange={(event, editor) => {
        onChange({target: {name: name, value: editor.getData()}})
      }}
      onBlur={(event, editor) => {
        console.log("Blur.", editor);
      }}
      onFocus={(event, editor) => {
        console.log("Focus.", editor);
      }}
    />
  );
};

export default InputCKEditor;
