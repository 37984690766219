import { useEffect } from "react";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import Alerts from "../../../../components/alerts/Alerts";
import BtnAccept from "../../../../components/forms/BtnAccept";
import CheckBox from "../../../../components/forms/CheckBox";
import Input from "../../../../components/forms/Input";
import TextTarea from "../../../../components/forms/TextTarea";
import { authContext } from "../../../../context/AuthContext";
import ApiRest from "../../../../hooks/useRest";
import { fetchFeatures, fetchFeaturesTypes } from "../../../../slices/features/Index";
import { AttachFeatures } from "../../../../utils/AttachFeatures";
import { Validate } from "../../../../utils/ValidateForms";
import InputCKEditor from "../../../../components/forms/InputCKEditor";

const ModalFeature = ({
  modal,
  setModal,
  setLoading,
  loading,
  setSelect,
  select,
}) => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { FORM, VALIDATOR, URLs } = context;

  const { URL_UPDATE_FEATURES, URL_CREATE_FEATURES, URL_INDEX_FEATURES_TYPES } = URLs;
  const { FORM_FEATURES } = FORM;
  const { VALIDATION_FEATURES } = VALIDATOR;

  const [form, setForm] = useState(
    JSON.parse(JSON.stringify(FORM_FEATURES))
  );

  const [validate, setValidate] = useState(
    JSON.parse(JSON.stringify(VALIDATION_FEATURES))
  );

  const onClick = async (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
      case "add_feature":
        {
          //Delete property
          const validation = Validate(form);
          setValidate(validation);

          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          let formData = new FormData();
          formData.append('name', form.name[0]);
          formData.append('description', form.description[0]);
          formData.append('features_types', modal.id);

          const response = await ApiRest({
            url: URL_CREATE_FEATURES,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {

            let params = {
              url: URL_INDEX_FEATURES_TYPES,
            };

            dispatch(fetchFeaturesTypes(params));

            //cerrar modal
            Alerts({
              msg: "Alert",
              title: "Creando nueva caracteristica...",
              code: "201",
              type: "success",
            });

            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "save_feature":
        {
          //Delete property
          const validation = Validate(form);
          setValidate(validation);

          //Validaciones
          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          let formData = new FormData();
          formData.append('id', select.id);
          formData.append('name', form.name[0]);
          formData.append('description', form.description[0]);
          formData.append('features_types', modal.id);

          const response = await ApiRest({
            url: URL_UPDATE_FEATURES,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {

            let params = {
              url: URL_INDEX_FEATURES_TYPES,
            };

            dispatch(fetchFeaturesTypes(params));

            //cerrar modal
            Alerts({
              msg: "Alert",
              title: "Actualizando característica...",
              code: "201",
              type: "success",
            });

            return setModal(false);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;

    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "description":
      case "name":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
            ['features_description']: [modal.description, ""],
            ["features_types_id"]: [modal.id, ""]
          });
        }
        break;
    }
  };

  useEffect(() => {
    if (select) {
      setForm(AttachFeatures(select))
    }
  }, [select])
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <div className="modal-header p-4">
            <h1 className="modal-title fs-3" id="nueva-ordenModalLabel">
              Nueva Características “{modal.name}”
            </h1>
            <label className="btn btn-close">
              <button
                type="button"
                className="d-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                name="close"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>
          <div className="modal-body p-5">
            <form>
              <div className="mb-3 ">
                <label className="form-label">Descripcion de la caracteristica</label>
                <InputCKEditor
                  data={form.description[0]}
                  name="description"
                  onChange={onChange}
                />
                {
                  /* 
                  <TextTarea
                  onChange={onChange}
                  label={"Descripción del servicio"}
                  name={"description"}
                  placeholder={"Añade una descripcion"}
                  type={"text"}
                  value={form.description[0]}
                  validate={validate.description}
                  />
                  */
                }
              </div>
              <div className="w-100 d-flex justify-content-end">
                {select ? (
                  <BtnAccept
                    onClick={onClick}
                    name={"save_feature"}
                    value={"GUARDAR CARACTERISTICAS"}
                    loading={loading}
                    loadingMsg={"Creando..."}
                  />
                ) : (
                  <BtnAccept
                    onClick={onClick}
                    name={"add_feature"}
                    value={"AÑADIR CARACTERISTICA"}
                    loading={loading}
                    loadingMsg={"Creando..."}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFeature;
