const BtnAccept = ({ onClick, name, value, loading, loadingMsg, icon }) => {
  return (
    <label className={loading ? "btn btn-primary disabled" : "btn btn-primary"}>
      {loading ? (
        <div className="d-flex align-items-center">
          <div className="spinner-grow spinner-grow-sm me-1 bg-miel" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <span className="ms-2">{loadingMsg}</span>
        </div>
      ) : (
        value
      )}

      {
        icon? <img /> : ""
      }
      
      <button
        type="submit"
        className="d-none"
        name={name}
        onClick={(event) => onClick(event)}
      ></button>
    </label>
  );
};

export default BtnAccept;
