import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  clients: null,
  search: []
}

export const clientSlice = createSlice({
  name: "clients",
  initialState: initialState,
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setClientsSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});


//Async functions
export const fetchClients = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setClients(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchSearchClients = (params) => (dispatch) => {
  dispatch(setClientsSearch(null));

  clientAxios
    .get(params.url, { params})
    .then((result) => {
      if(result.data.data.length == 0){
        return  dispatch(setClientsSearch([]));
      }
      return dispatch(setClientsSearch(result.data.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};




export const {setClients, setClientsSearch} = clientSlice.actions;

export const clientData = (state) => state.clients;

export default clientSlice.reducer;