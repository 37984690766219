import { useDispatch } from "react-redux";
import Input from "../../../../components/forms/Input";
import InputSearchMini from "../../../../components/forms/InputSearchMini";
import {
  fetchSearchClients,
  setClientsSearch,
} from "../../../../slices/clients/Index";
import { USD } from "../../../../utils/Price";
import { useState } from "react";
import ModalClient from "../../clients/modals/ModalClient";
import { useNavigate } from "react-router-dom";

const FormGeneral = ({
  type,
  form,
  setForm,
  setFormClient,
  formClient,
  validate,
  validateClient,
  id,
  template,
  data,
  urls,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  const { clients } = data;
  const { url_index_clients } = urls;

  const onChange = async (e) => {
    switch (e.target.name) {
      case "business_name":
      case "email":
        {
          let setCampo = formClient;
          setCampo[e.target.name][0] = e.target.value;

          setFormClient({
            ...formClient,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      case "time_lapse":
      case "title":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
      case "price":
        {
          //const price = await USD(e.target.value);

          let setCampo = form;
          setCampo["price"][0] = e.target.value;
          /* 
        Validate region 
        Argentina = 1
        Exterior = 2
        */
        console.log(formClient);
          if (formClient.regions_id[0] == 1) {
            let response = await USD(e.target.value);
            setCampo["oficial_price"][0] = response
          }

          setForm({
            ...form,
            [e.target.name]: setCampo["price"],
            ["oficial_price"]: setCampo["oficial_price"],
          });
        }
        break;
      case "searchClient":
        {
          //form
          let setCampo = formClient;

          //
          let params = {
            url: url_index_clients,
            search: e.target.value,
            status: 1,
          };

          if (
            e.target.value.length > 0 &&
            e.target.value.length > setCampo["name"][0].length
          ) {
            dispatch(fetchSearchClients(params));
          }

          if (e.target.value.trim() == "") {
            dispatch(setClientsSearch([]));
          }

          //Insert value form
          setCampo["name"][0] = e.target.value;

          setFormClient({
            ...formClient,
            name: setCampo["name"],
          });
        }
        break;
    }
  };

  const onClick = (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "name":
        {
          let setCampo = formClient;
          setCampo[e.target.name][0] = value.name;
          setCampo["id"][0] = value.id;
          setCampo["client_id"][0] = value.id;
          setCampo["business_name"][0] = value.business_name;
          setCampo["email"][0] = value.email;

          setFormClient({
            ...formClient,
            [e.target.name]: setCampo[e.target.name],
            ["id"]: setCampo["id"],
            ["client_id"]: setCampo["client_id"],
            ["business_name"]: setCampo["business_name"],
            ["email"]: setCampo["email"],
          });

          return dispatch(setClientsSearch([]));
        }
        break;
    }
  };

  //console.log(form);
  //console.log(formClient);
  return (
    <div class="card-conteiner mb-4">
      <h4 class="mb-4 d-flex">
        Información general
        {type == "project" ? (
          <label
            className={"ms-4 btn btn-primary p-2 d-flex justify-content-center items-aling-center"}
            style={{ fontSize: "13px" }}
            onMouseLeave={() => {
              document.getElementById("text-añadir-clientes").classList.remove("d-block")
              document.getElementById("text-añadir-clientes").classList.add("d-none")
            }}
            onMouseOut={() => {
              document.getElementById("text-añadir-clientes").classList.remove("d-none")
              document.getElementById("text-añadir-clientes").classList.add("d-block")
            }}
          >
            <svg
              width="15"
              height="15"
              className=""
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.686535 11.8045C0.686535 12.2762 0.855643 12.6943 1.19386 13.0588C1.53208 13.4233 1.92005 13.6056 2.35774 13.6056H9.28132L9.28131 21.1313C9.28131 21.603 9.45082 22.0103 9.78984 22.3534C10.1273 22.6964 10.5148 22.868 10.9525 22.868C11.3902 22.868 11.7782 22.6857 12.1164 22.3212C12.4546 21.9567 12.6237 21.5386 12.6237 21.0669V13.6056L19.607 13.6056C20.0447 13.6056 20.4227 13.4229 20.741 13.0575C21.0593 12.6939 21.2185 12.2762 21.2185 11.8045C21.2185 11.3328 21.0494 10.9147 20.7112 10.5503C20.373 10.1858 19.985 10.0035 19.5473 10.0035L12.6237 10.0035L12.6237 2.47781C12.6237 2.00611 12.455 1.59874 12.1176 1.25568C11.7786 0.912632 11.3902 0.741106 10.9525 0.741106C10.5148 0.741106 10.1269 0.923353 9.78865 1.28785C9.45042 1.65234 9.28131 2.07043 9.28131 2.54213L9.28132 10.0035L2.29806 10.0035C1.86036 10.0035 1.48274 10.1858 1.16522 10.5503C0.846094 10.9148 0.686535 11.3328 0.686535 11.8045Z"
                fill="white"
              />
            </svg>
            <span className="ms-2 d-none" id="text-añadir-clientes">Añadir Nuevo Cliente</span>
            <button
              href="#"
              className="d-none"
              name="apply_template"
              onClick={(event) => setModal(true)}
            ></button>
          </label>
        ) : (
          ""
        )}
      </h4>

      <form class="row">
        <div class="col-md-6 mb-4">
          <InputSearchMini
            onChange={onChange}
            label={"Nombre del cliente"}
            name={"searchClient"}
            type={"text"}
            value={formClient.name[0]}
            //Autocomplete
            onClick={onClick}
            nameAutocomplete="name"
            valueAutocomplete={clients}
            showIndex="name"
            sendIndex="id"
            validate={validateClient.name}
            //disabled={template ? false : id ? true : false}
          />
        </div>
        <div class="col-md-6 mb-4">
          <Input
            onChange={onChange}
            label={"Nombre de la empresa"}
            name={"business_name"}
            type={"text"}
            placeholder={"Nombre de la empresa"}
            value={formClient.business_name[0]}
            validate={validateClient.business_name}
            //disabled={template ? false : id ? true : false}
          />
        </div>
        <div class="col-md-6 mb-4">
          <Input
            onChange={onChange}
            label={"Email"}
            name={"email"}
            type={"email"}
            placeholder={"Email"}
            value={formClient.email[0]}
            validate={validateClient.email}
            //disabled={template ? false : id ? true : false}
          />
        </div>
        <div class="col-md-6 mb-4">
          <Input
            onChange={onChange}
            label={"Título de la propuesta"}
            name={"title"}
            type={"text"}
            placeholder={"Título de la propuesta"}
            value={form.title[0]}
            validate={validate.title}
          />
        </div>
        <div class="col-md-6 mb-4 mb-md-0">
          <Input
            onChange={onChange}
            label={"Tiempos"}
            name={"time_lapse"}
            type={"text"}
            placeholder={"Tiempos"}
            value={form.time_lapse[0]}
            validate={validate.time_lapse}
          />
        </div>
        <div class="col-md-6">
          <Input
            onChange={onChange}
            label={"Precio"}
            name={"price"}
            type={"number"}
            placeholder={"Precio"}
            value={form.price[0]}
            validate={validate.price}
            icon={"USD"}
          />
          <label>
            <span className="text-nowrap">
              {form.oficial_price[0] == ""
                ? ""
                : "ARS " +
                  new Intl.NumberFormat("de-DE").format(form.oficial_price[0])}
            </span>
          </label>
        </div>
      </form>

      {modal ? (
        <ModalClient setModal={setModal} select={null} navigate={navigate} />
      ) : (
        ""
      )}
    </div>
  );
};

export default FormGeneral;
