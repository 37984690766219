import { ConcatPayments } from "./ConcatPayments";

export const AttachPrices = (price) => {
  let form = {
    id: [price.id, ""],
    name: [price.name, "required"],
    description: [price.description, ""],
    price: [price.price, "required"],
    premium: [price.premium == 0? false : true , ""],
    projects_id: [price.projects_id, ""],
    time_lapse:[price.time_lapse ?? "", ""],
    status_id:[price.status_id, ""],

    payment_types_id: [ConcatPayments(price.payment_types), ""],
    payment_name: ["", ""],
    payment_config: ["cuota", ""],
  }

  return form
}