export const AttachClient = (client) => {

  let form = {
    id: [client.id, "required"],
    client_id: [client.id, "required"],
    name: [client.name, "required"],
    last_name: [client.last_name, ""],
    email: [client.email, "required"],
    business_name: [client.business_name, "required"],
    description: [client.description, ""],
    regions_id: [client.regions_id, "required"],
    status_id:[client.status_id, ""],
  }

  return form
}