import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  //Lista
  auth: "authenticated",
  me: null,

  //Roles
  roles: null
}

export const meSlice = createSlice({
  name: "me",
  initialState: initialState,
  reducers: {
    setNoAuth: (state, action) => {
      state.auth = false;
    },
    setMe: (state, action) => {
      state.me = action.payload;
      state.auth = true;
    },
    setLogout: (state, action) => {
      state.auth = false;
      state.me = null;
    },
  },
});


//Async functions
export const fetchMe = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setMe(result.data));
    })
    .catch((error) => {
      return dispatch(setNoAuth())
    });
};




export const {setNoAuth, setMe, setLogout} = meSlice.actions;

export const meState = (state) => state.me;

export default meSlice.reducer;