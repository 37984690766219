export const ConcatClient = (clients) => {
  let result = []

  clients.map((client) => {
    client = {
      ...client,
      region_name: client.regions.name,
      count_projects: client.projects.length
    }

    result.push(client)
  })

  return result
}