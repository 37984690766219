const Input = ({ onChange, label, name, type, value, validate, icon, nameType, uniqueKey, disabled, placeholder }) => {
  return (
    <div className="form-group m-0">
      <label for="email" className="form-label">
        {label}
      </label>
      <div className="input-group">
        {
          icon
          ? <span className="input-group-text" id="basic-addon1">
            <div contentEditable='true' dangerouslySetInnerHTML={{ __html: icon }}></div>
          </span>
          : ""
        }
        <input
          id={uniqueKey}
          type={type}
          value={value}
          className="form-control"
          aria-describedby="email"
          placeholder={placeholder? placeholder : ""}
          name={name}
          onChange={(event) => onChange(event)}

          disabled={disabled}
        />
        {
          nameType
          ? <span className="input-group-text right">{nameType}</span>
          : ""
        }
      </div>

      {validate.validate ? (
        <div className="text-danger">{validate.msg}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
