export const AttachFeatures = (feature) => {
  let form = {
    id: [feature.id, ""],
    name: [feature.name, ""],
    description: [feature.description, "required"],
    features_types_id: [feature.features_types_id, ""],
    status_id:[feature.status_id, ""],
  }

  return form
}