import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  projects: null,
  projects_eraser: null,
  projects_template: null
}

export const projectSlice = createSlice({
  name: "projects",
  initialState: initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setProjectsErase: (state, action) => {
      state.projects_eraser = action.payload;
    },
    setProjectsTemplate: (state, action) => {
      state.projects_template = action.payload;
    },
  },
});


//Async functions
export const fetchProjects = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      switch (params.status) {
        /* All */
        case null:{
          dispatch(setProjects(result.data))}
        break;
        /* Actives */
        case 1:{
           dispatch(setProjects(result.data));}
        break;
        /* Eraser */
        case 3:{
           dispatch(setProjectsErase(result.data));}
        break;
        /* Template */
        case 4:{
           dispatch(setProjectsTemplate(result.data));}
        break;
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};




export const {setProjects, setProjectsErase, setProjectsTemplate} = projectSlice.actions;

export const projectData = (state) => state.projects;

export default projectSlice.reducer;