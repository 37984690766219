const TextTarea = ({ onChange, label, name, value, index, validate }) => {
  return (
    <div>
      <label for="comprador" class="form-label">
        {label}
      </label>
      <textarea
        class="form-control"
        id="exampleFormControlTextarea1"
        rows="3"
        value={value}
        
        name={name}
        onChange={(event) => onChange(event)}
      ></textarea>

        {
        validate.validate
        ? <div className="text-danger">{validate.msg}</div>
        : ""
        }
    </div>
  );
};

export default TextTarea;
