import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  faqs: null,
  faqs_project: null,
  search: null
}

export const faqsSlice = createSlice({
  name: "faqs",
  initialState: initialState,
  reducers: {
    setFaqs: (state, action) => {
      state.faqs = action.payload;
    },
    setFaqsSearch: (state, action) => {
      state.search = action.payload;
    },
    setFaqsProject: (state, action) => {
      state.faqs_project = action.payload;
    },  
    },
});


//Async functions
export const fetchFaqsProject = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setFaqsProject(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchFaqs = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setFaqs(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchFaqSearch = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setFaqsSearch(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};




export const {setFaqs, setFaqsSearch, setFaqsProject} = faqsSlice.actions;

export const faqData = (state) => state.faqs;

export default faqsSlice.reducer;