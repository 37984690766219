import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alerts from "../../../../components/alerts/Alerts";
import BtnAccept from "../../../../components/forms/BtnAccept";
import Input from "../../../../components/forms/Input";
import Spinner from "../../../../components/loads/Spinner";
import { authContext } from "../../../../context/AuthContext";
import ApiRest from "../../../../hooks/useRest";
import { fetchClients } from "../../../../slices/clients/Index";
import { fetchRegions, regionData } from "../../../../slices/regions/Index";

const ModalClient = ({ setModal, select }) => {
  const dispatch = useDispatch();
  const context = useContext(authContext);
  const { URLs, FORM, VALIDATOR, HARDDATA } = context;

  const { URL_INDEX_REGIONS, URL_CREATE_CLIENT, URL_UPDATE_CLIENT, URL_INDEX_CLIENT } = URLs;

  const _regions = useSelector(regionData);
  const regions = _regions.regions;

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: ["", ""],
    last_name: ["", ""],
    business_name: ["", ""],
    email: ["", ""],
    status: ["", ""],
    regions_id: [null, ""],
    regions: ["", ""],
  });

  const onClick = async (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      case "close":
        {
          setModal(false);
        }
        break;
      case "save":{
        let formData = new FormData();
        formData.append("id", form.id[0]);
        formData.append("name", form.name[0]);
        //formData.append("last_name", form.last_name[0]);
        formData.append("business_name", form.business_name[0]);
        formData.append("mail", form.email[0]);
        formData.append("regions_id", form.regions_id[0]);

        const response = await ApiRest({
          url: URL_UPDATE_CLIENT,
          formData: formData,
          setLoading: setLoading,
          method: "post",
        });


        //Error
        if (response.status == 201 || response.status == 200) {
          let params = {
            url: URL_INDEX_CLIENT,
            status: 1,
          }
          dispatch(fetchClients(params))

          Alerts({
            msg: "Alert",
            title: "Nuevo cliente creado",
            code: "201",
            type: "success",
          });
          return setModal(false);
        }

        Object.keys(response.data).map((key) => {
          return Alerts({
            msg: "Alert",
            title: response.data[key][0],
            code: "401",
            type: "error",
          });
        });
      } break;
      case "add": {
        let formData = new FormData();
        formData.append("name", form.name[0]);
        //formData.append("last_name", form.last_name[0]);
        formData.append("business_name", form.business_name[0]);
        formData.append("mail", form.email[0]);
        formData.append("regions_id", form.regions_id[0]);

        const response = await ApiRest({
          url: URL_CREATE_CLIENT,
          formData: formData,
          setLoading: setLoading,
          method: "post",
        });


        //Error
        if (response.status == 201 || response.status == 200) {
          let params = {
            url: URL_INDEX_CLIENT,
            status: 1,
          }
          dispatch(fetchClients(params))

          Alerts({
            msg: "Alert",
            title: "Nuevo cliente creado",
            code: "201",
            type: "success",
          });
          return setModal(false);
        }

        Object.keys(response.data).map((key) => {
          return Alerts({
            msg: "Alert",
            title: response.data[key][0],
            code: "401",
            type: "error",
          });
        });
      } break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "name":
      case "email":
      case "business_name":
      case "regions_id":
        {
          let setCampo = form;
          setCampo[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: setCampo[e.target.name],
          });
        }
        break;
    }
  };

  useEffect(() => {
    if (!regions) {
      let params = {
        url: URL_INDEX_REGIONS,
      };

      dispatch(fetchRegions(params));
    }
  }, []);

  useEffect(() => {
    if (select) {
      setForm({
        id: [select.id, ""],
        name: [select.name, ""],
        business_name: [select.business_name, ""],
        regions: [select.regions.name, ""],
        regions_id: [select.regions_id],
        email: [select.email, ""],
        status: [select.status.name, ""],
      });
    }
  }, []);
  return (
    <div
      className="modal fade show bg-dark bg-opacity-25"
      style={{ display: "block" }}
      id="nueva-ordenModal"
      tabindex="-1"
      aria-labelledby="nueva-ordenModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-modal-blanco" style={{ zIndex: 4 }}>
          <div className="modal-header p-4">
            <h1 className="modal-title fs-3" id="nueva-ordenModalLabel">
              Cliente
            </h1>
            <label className="btn btn-close">
              <button
                type="button"
                className="d-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                name="close"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>
          <div className="modal-body p-5">
            {form && regions ? (
              <form>
                <div className="mb-3">
                  <Input
                    onChange={onChange}
                    label={"Nombre de la empresa"}
                    name={"business_name"}
                    type={"text"}
                    placeholder={"..."}
                    value={form.business_name[0]}
                    validate={{ msg: "", validate: false }}
                  />
                </div>
                <div className="mb-3">
                  <Input
                    onChange={onChange}
                    label={"Nombre del cliente"}
                    name={"name"}
                    type={"text"}
                    placeholder={"..."}
                    value={form.name[0]}
                    validate={{ msg: "", validate: false }}
                  />
                </div>
                <div className="mb-3">
                  <Input
                    onChange={onChange}
                    label={"Email del cliente"}
                    name={"email"}
                    type={"text"}
                    value={form.email[0]}
                    validate={{ msg: "", validate: false }}
                  />
                </div>
                <div className="mb-3">
                  {regions.map((r, index) => (
                    <>
                      <input
                        type="radio"
                        class="btn-check"
                        id={"btnradio1_modal_" + index}
                        autocomplete="off"
                        name="regions_id"
                        onChange={(event) => onChange(event, r)}
                        value={r.id}
                        checked={form.regions_id[0] == r.id ? true : false}
                      />
                      <label
                        class="btn btn-outline-primary"
                        for={"btnradio1_modal_" + index}
                      >
                        {r.name}
                      </label>
                    </>
                  ))}
                </div>
                <div className="w-100 d-flex justify-content-end">
                  {select ? (
                    <BtnAccept
                      onClick={onClick}
                      name={"save"}
                      value={"APLICAR CAMBIOS"}
                      loading={loading}
                      loadingMsg={"Creando..."}
                    />
                  ) : (
                    <BtnAccept
                      onClick={onClick}
                      name={"add"}
                      value={"AÑADIR CLIENTE"}
                      loading={loading}
                      loadingMsg={"Creando..."}
                    />
                  )}
                </div>
              </form>
            ) : (
              <div>
                <Spinner/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalClient;
