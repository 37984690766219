import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  methodologies: null,
}

export const methodologySlice = createSlice({
  name: "methodologies",
  initialState: initialState,
  reducers: {
    setMethodologies: (state, action) => {
      state.methodologies = action.payload;
    },
  },
});


//Async functions
export const fetchMethodologies = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setMethodologies(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};




export const {setMethodologies} = methodologySlice.actions;

export const methodologyData = (state) => state.methodologies;

export default methodologySlice.reducer;