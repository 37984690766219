import { AttachFeatures } from "./AttachFeatures";
import { AttachPrices } from "./AttachPrices";
import { AttachServices } from "./AttachServices";
import { ConcatFeature } from "./ConcatFeature";
import { ConcatPayments } from "./ConcatPayments";

export const AttachProject = (project, features_types) => {
  let orders = [];

  /* Add Services */
  project.services.map((service, index) => {
    project.services[index] = AttachServices(service);
  });

  project.price_premium = null;
  project.price_basico = null;

  project.prices.map((price, index) => {
    project.prices[index] = AttachPrices(price);
    if(price.premium == 1){
      project.price_premium = AttachPrices(price);
    } else {
      project.price_basico = AttachPrices(price);
    }
  });

  project.features.map((feature, index) => {
    orders.push(feature.pivot);
    project.features[index] = ConcatFeature(feature);
  });

  /* Order feature */
  let result = [];
  features_types.map((f) => {
    let fea = []

    f.features.map((e, index) => {
      let pivot = orders.filter((x) => x.features_id == e.id)

      e = {
        ...e,
        pivot:  pivot.length > 0? pivot[0].position == 100 ? [{
          position: index
        }] : pivot : [{
          position: index
        }],
      };

      fea.push(e);
    });

    f = {
      ...f,
      features: fea
    }

    result.push(f)
  });

  //setOrderFeautres(result);

  /* */

  project.faqs.map((faq, index) => {
    project.faqs[index] = faq.id;
  });

  let form = {
    id: [project.id, "required"],
    template: [project.template, ""],
    title: [project.title, "required"],
    description: [project.description, ""],
    title_option: [project.title_option, "required"],
    description_option: [project.description_option, "required"],
    price: [project.price, "required"],
    oficial_price: ["", ""],
    link: [project.link, ""],
    status_terms_and_conditions: [project.status_terms_and_conditions, ""],
    time_lapse: [project.time_lapse, "required"],
    payment_types_id: [ConcatPayments(project.payment_types), ""],
    payment_name: [null, ""],
    payment_config: ["cuota", ""],
    users_id: [project.users_id, ""],
    terms_and_conditions_id: [project.terms_and_conditions_id, ""],
    clients_id: [project.clients_id, ""],
    status_id: [project.status_id, ""],
    methodology_id: [project.methodology_id, "required"],

    services: [project.services, ""],
    
    prices: [project.prices, ""],
    price_basico: [project.price_basico, ""],
    price_premium: [project.price_premium, ""],

    features: [project.features, ""],
    faqs: [project.faqs, ""],
  };

  return form;
};
