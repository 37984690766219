import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../config/ClientAxios";

const initialState = {
  regions: null,
}

export const regionSlice = createSlice({
  name: "regions",
  initialState: initialState,
  reducers: {
    setRegions: (state, action) => {
      state.regions = action.payload;
    },
  },
});


//Async functions
export const fetchRegions = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params})
    .then((result) => {
      return dispatch(setRegions(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};




export const {setRegions} = regionSlice.actions;

export const regionData = (state) => state.regions;

export default regionSlice.reducer;